html, body {
    overscroll-behavior: none !important;
}

body {
    height: auto;
    overscroll-behavior: none;
    margin: 0;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: 'Almarena', 'Yellix Medium Italic', 'Yellix',  'Yellix ExtraBold';
}

#title {
    font-family: 'Almarena';
}
